<template>
    <div id="home">
        
        <van-row type="flex" justify="center" class="content">
            
            <van-col v-if="isVertical == 'vertical'" span="24" ref="content" style="height:100%">
                <van-list class="productList" v-model="loading" :finished="finished" finished-text="没有更多了" @load="productLoad" :immediate-check="false">
                    <van-swipe v-if="bannerShow" :autoplay="0" class="swipe" indicator-color="#3296FA">
                        <van-swipe-item  v-for="(item, index) in bannerList" :key="index">
                            <img :src="item.imgUrl" @click="jump(item.url)">
                        </van-swipe-item>
                    </van-swipe>
                    <div v-if="screenData.length>0" :class="position == 1 ? 'screenBox screenR' : 'screenBox screenL'">
                        <button @click="search">高级筛选</button>
                    </div>

                    <masonry :cols="4" :gutter="10" class="waterfall03">
                        <div v-for="(item, index) in productList" :key="index" @click="jump(item.id, item.openFlag, item.shareCode)" class="listBox">
                            <img v-lazy="item.thumbnail" />
                            <i class="iconfont icon-n-jingxuanfangan isBest" v-if="item.isBest"></i>
                            <i class="iconfont icon-n-jiamifangan isBest" v-if="item.openFlag"></i>
                            <div class="introduce">
                                <div class="item-name">
                                    <h4>{{item.name}}</h4>
                                    <p>
                                        <i class="iconfont  icon-chakan"></i><span>{{item.browseCount}}</span>
                                        <i class="iconfont icon-xingxing"></i><span>{{item.collectionCount}}</span>
                                    </p>
                                </div>
                                <div class="item-footer">
                                    <i v-if="item.favFlag" class="iconfont icon-shoucang1" @click.stop="cancelCollection(item.id, index)"></i>
                                    <i  v-else class="iconfont icon-xin3 collection" @click.stop="onChangeCollection(item.id, index)"></i>
                                </div>
                            </div>
                        </div>
                    </masonry>
                </van-list>
            </van-col>
            
            <van-col v-else span="24" ref="content" style="height:100%">
                <van-list class="productList" v-model="loading" :finished="finished" finished-text="没有更多了" @load="productLoad" :immediate-check="false">
                    <van-swipe v-if="bannerShow" :autoplay="3000" class="swipe" indicator-color="#3296FA">
                        <van-swipe-item  v-for="(item, index) in bannerList" :key="index">
                            <img :src="item.imgUrl" @click="jump(item.url)">
                        </van-swipe-item>
                    </van-swipe>
                    <div v-if="screenData.length>0" :class="position == 1 ? 'screenBox screenR' : 'screenBox screenL'">
                        <button @click="search">高级筛选</button>
                    </div>

                    <masonry :cols="4" :gutter="10" class="waterfall03">
                        <div v-for="(item, index) in productList" :key="index" @click="jump(item.id, item.openFlag, item.shareCode)" class="listBox">
                            <img v-lazy="item.thumbnail" />
                            <i class="iconfont icon-n-jingxuanfangan isBest" v-if="item.isBest"></i>
                            <i class="iconfont icon-n-jiamifangan isBest" v-if="item.openFlag"></i>
                            <div class="introduce">
                                <div class="item-name">
                                    <h4>{{item.name}}</h4>
                                    <p>
                                        <i class="iconfont  icon-chakan"></i><span>{{item.browseCount}}</span>
                                        <i class="iconfont icon-xingxing"></i><span>{{item.collectionCount}}</span>
                                    </p>
                                </div>
                                <div class="item-footer">
                                    <i v-if="item.favFlag" class="iconfont icon-shoucang1" @click.stop="cancelCollection(item.id, index)"></i>
                                    <i  v-else class="iconfont icon-xin3 collection" @click.stop="onChangeCollection(item.id, index)"></i>
                                </div>
                            </div>
                        </div>
                    </masonry>
                </van-list>
            </van-col>
        
        </van-row>
        

        <van-popup v-model="collectionModal" class="addCollection">
            <AddCollection ref="collection" :productId="productId" :productIndex="productIndex" @confirmCollection="confirmCollection"/>
            <div class="cancelTips">点击空白处--“取消收藏”</div>
        </van-popup>

        <van-popup v-model="screenModal" :position="position == 1 ? 'right' : 'left'" :class="position == 1 ? 'screenModal mr' : 'screenModal ml'">
            <div class="header">高级筛选</div>
            <div class="content">
                <div v-for="(item, ulIndex) in screenData" :key="ulIndex">
                    <h4>{{item.name}}</h4>
                    <ul>
                        <li ref="searchList" v-for="(item, index) in item.labels" :key="index" @click="selectScreen($event, ulIndex)">{{item.name}}</li>
                    </ul>
                </div>
                <div v-if="!screenData" class="screenTips">还没有属性</div>
            </div>
            <div class="footer">
                <button @click="confirmScreen">确认</button>
                <button @click="screenModal = false">取消</button>
            </div>
        </van-popup>

        <van-popup v-model="encryptionModal" class="encryptionModal">
            <div class="header">
                <h4>请输入密码</h4>
            </div>
            <div class="modalContent">
                <input type="password" v-model="encryptionData.password"> 
                <button @click="verification">确认</button>
            </div>
        </van-popup>

        <rotate-square5 v-if="isLoading" class="loading"></rotate-square5>
    
        <div v-if="isScore && isVertical" :class="position == 1 ? 'gift giftVertical gift_r' : 'gift giftVertical gift_l'" @click="jumpGift">
            <img src="../images/gift.gif"/>
        </div>
        <div v-else-if="isScore" :class="position == 1 ? 'gift gift_r' : 'gift gift_l'" @click="jumpGift">
            <img src="../images/gift.gif"/>
        </div>

    </div>
</template>

<script>
import {getBanner, getTypes, getProduct, removeFav} from '../api/index'
import {score} from '../api/eggs'
import AddCollection from './addCollection'
import {RotateSquare5} from 'vue-loading-spinner'
export default {
    data () {
        return {
            token: '',
            position: '',  //1右 2左
            bannerList: [],
            bannerShow: false,
            loading:false,
            finished: false,
            isLoading:false, //控制加载动画
            activeKey: 0, //导航定位
            typesList: '', //导航列表
            productData: { //获取产品入参
                token: '',
                typeId: 0, //0表示推荐产品,
                labels: '',
                key: '', //产品名称检索
                index: 1, //分页 
                pageSize: 20
            },
            productList: '', //产品列表
            loadingDotStyle: { //loading动画内小圆点的样式对象，自定义其样式
                background: '#3CA8FE',
            },
            collectionModal: false,
            productId: '',
            productIndex: '',
            screenModal: false, //筛选弹窗
            screenData:'', //筛选数据
            screenLabels:[], //筛选条件
            col:5,
            isLoaded: false,
            screenIsShow: false, //控制筛选按钮出现
            encryptionModal: false, //验证密码弹窗
            encryptionData: {
                id: '',
                password: '',
                code: ''
            },
            currentAndioId: '',
            isVertical: '', //是否为竖屏
            isScore: false, //进店有礼显示
            
        }
    },
    mounted () {
        const {token, id, vertical, position} = this.$route.query;
        vertical && (this.isVertical = vertical);
        position && (this.position = position);
        this.token = token;
        this.banner();
        this.getScore();
        window.onChangeType = this.onChange;
    },
    activated () {
        const scrollTop = this.$route.meta.scrollTop;
        const $content = document.querySelector('.productList');
        if (scrollTop && $content) {
            $content.scrollTop = scrollTop;
        }
        this.types();
        this.getScore();
        // alert('缓存组件激活，产品id'+this.productData.typeId)
    },
    components:{ AddCollection, RotateSquare5},
    methods: {

        /* 获取进店有礼进度 */
        async getScore () {
            const {token} = this;
            let res = await score({token});
            if (res.status == 0) {
                res.data.score > 0 ? this.isScore = true : this.isScore = false;
            } else {
                this.isScore = false;
            }
        },

        //banner
        async banner () {
            const {token} = this;
            let res = await getBanner(token);
            this.bannerList = res.data;
            this.bannerList.map(data => {
               data.url && (data.url = data.url.split('?')[1].split('=')[1]);
            });
            setTimeout(() => {this.bannerShow = true},500)
        },

        //获取导航
        async types () {
            const {token, $route} = this;
            const {typeId} = $route.query;
            let res = await getTypes(token);
            this.typesList = res.data;
            // console.log(typeId)
            typeId ? this.onChange(typeId) : this.product();
        },

        //获取产品 初始化默认获取推荐分类下产品
        async product () {
            this.productList = [];
            const {token, productData, isVertical} = this;
            this.productData.token = token;
            isVertical == 'vertical' && (this.productData.pageSize = 40);
            this.isLoading = true;
            // alert(`获取数据前的id：${productData.typeId}`);
            let res = await getProduct(productData);
            const {status, data} = res;
            this.productList = data.list;
            if (status == 0){
                this.isLoading = false;
                data.list.length < 20 && (this.finished = true);
            }
            // alert(`获取数据前的id：${productData.typeId}`);
        },

        //设置position
        setPosition (position) {
            this.position = parseInt(position);
            localStorage.setItem('position',position);
        },

        //切换导航
        onChange (id) {
            const {typesList} = this;
            this.screenLabels = [];
            this.productData.labels = '';
            this.loading = false;
            this.finished = false;
            this.screenModal = false;
            if (id != 0) {
                this.screenIsShow = true;
                this.productData.typeId = id;
                this.productData.index = 1;
                // alert(`给小军调用的方法：${id}`);
                this.product(); 
                //获取当前分类下的筛选数据
                    console.log(typesList);
                typesList.list.map(data => {
                    if (data.id == id && data.clesses) {
                        this.screenData = data.clesses;
                        return;
                    }
                })
                //初始化screenlabels
                let arr = [];
                this.screenData.map(data => {
                    arr.push({id: data.id, val: []});
                });
                this.screenLabels = arr;
                console.log(this.screenLabels);
            } else {
                this.screenIsShow = false;
                this.productData.typeId = 0;
                this.productData.index = 1;
                this.product();
            }
        },
        
        //加载更多
        async productLoad () {
            const {productData} = this;
            this.productData.index++;
            let res = await getProduct(productData);
            console.log(res);
            const {status, data} = res;
            if (status == 0 && data.list.length > 0) {
                this.productList.push(...data.list);
                this.loading = false;
            } else if (status == 0 && data.list.length < 20) {
                this.finished = true;
            }
        },

        //每次图片预加载完成后找出最后一张图片增加距底部高度
        preloaded () {
            setTimeout(() => {
                document.querySelector(`.vue-waterfall-easy>div:nth-child(${this.productList.length})`).style.marginBottom = '100px';
            })
        },

        //点击添加收藏操作
        onChangeCollection (id, index) {
            this.collectionModal = true;
            this.productId = id;
            this.productIndex = index;
            setTimeout(() => {
                this.$refs.collection.restTypes();
            })
        },

        //确认收藏后操作
        confirmCollection (data) {
            const {model, productIndex, isFav} = data;
            this.collectionModal = model;
            isFav && (this.productList[productIndex].favFlag = true);
        },
        
        //取消收藏
        async cancelCollection (id, index) {
            const {token} = this;
            let data = {token, id};
            let res = await removeFav(data);
            if (res.status == 0) {
                this.productList[index].favFlag = false;
            }
        },
        
        jump (id, openFlag, code) {
            const {token, $router, activeKey, isVertical, position} = this;
            if (openFlag) {
                this.encryptionModal = true;
                this.encryptionData.id = id;
                this.encryptionData.code = code;
            } else {
                $router.push({name: 'productDetails', query:{token, id, position, vertical: isVertical}});
            }
           
        },
        //验证
        verification () {
            const {token, $router, position, activeKey, encryptionData} = this;
            const {id, password, code} = encryptionData;
            if (password === code) {
                this.encryptionModal = false;
                this.encryptionData.password = '';
                $router.push({name: 'productDetails', query:{token, id, position}});
            } else {
                this.$toast('密码错误！');
            }
        },

        //选择筛选条件 
        selectScreen (e, index) {
            let target = e.target;
            if (target.className) {
                target.className = '';
                let val = this.screenLabels[index].val;
                for (let i in val) {
                    val[i] == target.innerText && val.splice(i, 1);
                };
            } else {
                target.className = 'active';
                this.screenLabels[index].val.push(target.innerText)
            }
        },

        //
        search () {
            this.screenModal = true;
            setTimeout(() => {
                this.$refs.searchList.map(data => {
                    data.className = '';
                });
            })
        },

        //确认筛选
        async confirmScreen () {
            const {screenLabels} = this;
            let txt = '';
            screenLabels.map(data => {
                data.val.length > 0 && (txt+=`${data.id}|${data.val.join(',')}$`);
            });
            txt = txt.substring(0, txt.lastIndexOf('$'));
            this.productData.index = 1;
            this.productData.labels = txt;
            let res = await getProduct(this.productData);
            console.log(res);
            const {list, total} = res.data;
            if (total != 0) {
                this.productList = list;
                this.screenModal = false;
                this.screenLabels.map(data => {
                    data.val = [];
                })
            } else {
                this.$toast('没有找到方案，换个条件试试吧~');
            }
        },

        jumpGift () {
            const {token, $router, isVertical} = this;
            $router.push({name: 'eggs', query:{token, vertical:isVertical}});
        }
        
    }
}
</script>

<style lang="less" src="@/style/index.less">

</style>